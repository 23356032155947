<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div>
    <v-row>
      <v-col class="title">
        <h1>{{ formTitle }}</h1>
        <v-divider />
      </v-col>
    </v-row>
    <v-form
      id="iiotRoleDetailForm"
      ref="roleDetailForm"
      @submit.prevent="saveRole"
    >
      <v-row>
        <v-col
          cols="12"
          lg="3">
          <v-text-field
            v-if="role.type=== 'ldap'"
            id="iiotRoleDetailsFormName"
            ref="roleNameInput"
            v-model="role.prettyRoleName"
            :disabled="!((roleId && canAccess('UI_ROLE:EDIT')) ||
            (!roleId && canAccess('UI_ROLE:CREATE')))
            || role.type === 'ldap'
            || isLoggedLdapUser"
            :rules="[rules.roleNameRequired,
            rules.noSpecialCharactersInName, rules.roleNameMaxLength]"
            :label="$t('access.roles.roleDetails.name')"
            autofocus
            max="ROLE_NAME.MAX_LENGTH"
          />
          <v-text-field
            v-else
            id="iiotRoleDetailsFormName"
            ref="roleNameInput"
            v-model="role.name"
            :disabled="!((roleId && canAccess('UI_ROLE:EDIT')) ||
            (!roleId && canAccess('UI_ROLE:CREATE'))) || role.type === 'ldap' || isLoggedLdapUser"
            :rules="[rules.roleNameRequired, rules.noSpecialCharactersInName,
             rules.roleNameMaxLength]"
            :label="$t('access.roles.roleDetails.name')"
            autofocus
            max="ROLE_NAME.MAX_LENGTH"
          />
        </v-col>
        <v-col
          cols="12"
          lg="3"
        >
          <v-text-field
            id="iiotRoleDetailsFormDescription"
            ref="roleDescriptionInput"
            v-model="role.description"
            :disabled="!((roleId && canAccess('UI_ROLE:EDIT'))
            || (!roleId && canAccess('UI_ROLE:CREATE'))) ||
            role.type === 'ldap' || isLoggedLdapUser"
            :rules="[rules.roleDescriptionRequired,
             rules.roleDescriptionMaxLength]"
            :label="$t('access.roles.roleDetails.description')"
            max="ROLE_DESCRIPTION.MAX_LENGTH"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="pb-0"
        >
          <role-permissions />
        </v-col>
      </v-row>
      <v-col>
        <v-row>
          <v-col cols="12" class="pa-0 pt-4">
            <div>
              <nerve-button
                id="iiotRoleDetailsFormCancel"
                :text="$t('access.roles.roleDetails.cancelBtn')"
                type-of-btn="cancel"
                size="normal"
                class="mr-5 ml-0"
                ref="roleDetailsCancelBtn"
                @click-event="goToRoles()"
              />
              <nerve-button
                v-if="(!roleId && !canAccess('UI_ROLE:EDIT'))
                || (roleId && canAccess('UI_ROLE:EDIT') &&
                (isLoggedLdapUser ? role.type === 'ldap' : true))
                || (!roleId && canAccess('UI_ROLE:CREATE'))"
                id="iiotRoleDetailsFormSave"
                :text="roleId === '' ? $t('access.roles.roleDetails.saveBtn')
                : $t('access.roles.roleDetails.updateBtn')"
                type-of-btn="action"
                size="normal"
                ref="roleDetailsSaveBtn"
                type="submit"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-form>
  </div>
</template>

<script>
/* eslint-disable func-names */
import { NerveButton } from 'nerve-ui-components';
import RolePermissions from '@/components/roles/RolePermissions.vue';
import { ROLE_NAME, ROLE_DESCRIPTION, VALIDATION_REGEX } from '@/constants';
import Logger from '@/utils/logger';

export default {
  components: {
    RolePermissions,
    NerveButton,
  },
  data: () => ({
    ROLE_NAME,
    ROLE_DESCRIPTION,
    VALIDATION_REGEX,
    isLoggedLdapUser: false,
    roleId: '',
  }),
  created() {
    // eslint-disable-next-line prefer-destructuring
    this.roleId = window.location.pathname.split('/')[2] === 'new' ? '' : window.location.pathname.split('/')[2];
  },
  computed: {
    rules() {
      return {
        roleNameRequired: (value) => !!value || this.$t('access.roles.roleDetails.roleNameRequired'),
        roleDescriptionRequired: (value) => (this.role.type === 'ldap' ? true : !!value || this.$t('access.roles.roleDetails.roleDescriptionRequired')),
        roleNameMaxLength: () => this.role.name.length <= this.ROLE_NAME.MAX_LENGTH || this.$t('access.roles.roleDetails.roleNameMaxLengthMsg', { msgLength: this.ROLE_NAME.MAX_LENGTH }),
        roleDescriptionMaxLength: () => this.role.description.length <= this.ROLE_DESCRIPTION.MAX_LENGTH || this.$t('access.roles.roleDetails.roleDescriptionMaxLengthMsg', { msgLength: this.ROLE_DESCRIPTION.MAX_LENGTH }),
        noSpecialCharactersInName: (value) => (this.role.type === 'ldap' ? true : VALIDATION_REGEX.ONLY_LETTERS_AND_NUMBERS.test(value) || this.$t('access.roles.roleDetails.roleNameSpecialCharacters')),
      };
    },
    formTitle() {
      return this.roleId === '' ? this.$t('access.roles.roleDetails.newRoleTitle') : this.$t('access.roles.roleDetails.editRoleTitle');
    },
    role() {
      return this.$store.getters['roles/getRole'];
    },
    getRolePermissions() {
      return this.$store.getters['roles/getRolePermissions'];
    },
  },
  watch: {
    'role.name': function () {
      try {
        this.$store.dispatch('roles/update_role', this.role);
      } catch (e) {
        Logger.error(e);
      }
    },
    'role.description': function () {
      try {
        this.$store.dispatch('roles/update_role', this.role);
      } catch (e) {
        Logger.error(e);
      }
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch('roles/fetch_role', this.roleId);
      if (JSON.parse(localStorage.getItem('userDetails')) && JSON.parse(localStorage.getItem('userDetails')).ldap) {
        this.isLoggedLdapUser = true;
      }
    } catch (e) {
      Logger.error(e);
    }
  },
  methods: {
    async saveRole() {
      try {
        if (!this.$refs.roleDetailForm.validate()) {
          return;
        }
        if (this.getRolePermissions && this.getRolePermissions.length === 0) {
          await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', { text: 'access.roles.roleDetails.emptyRoleMsg', color: 'red', showClose: true });
          return;
        }
        await this.$store.dispatch('roles/save_role', this.roleId);
        await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: this.roleId ? 'access.roles.roleDetails.updatedSuccessfully' : 'access.roles.roleDetails.createdSuccessfully',
          color: 'success',
          showClose: true,
        });
        this.goToRoles();
      } catch (e) {
        await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: `errorMessages.${e.errorCode}`, color: 'red', showClose: true,
        });
      }
    },
    goToRoles() {
      this.$router.go(-1);
    },
  },
};
</script>
