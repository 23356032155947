<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <the-nerve-table
    id="iiotApiPermissionTable"
    ref="apiPermissionTable"
    :columns="apiPermissionTableHeaders"
    :empty-state="$t('access.roles.roleDetails.noApiPermissionsToShow')"
    :is-action-menu-enabled="false"
    :params="fetchParams"
    :disable-pagination="true"
    :is-check-box-column-enabled="true"
    :hide-footer="true"
    :is-autofocus-enabled="false"
    custom-fetch-action="fetch_permissions"
    custom-search-action="search_permissions"
    store-module="roles"
    custom-list-getter="getDisplayedPermissions"
    selected-items-getter="getRolePermissions"
    item-key="_id"
    check-item-action="toggle_permission"
    check-all-items-action="toggle_all_permissions"
    :is-row-clickable="canAccess('UI_ROLE:CREATE')
    && isLoggedLdapUser ? role.type === 'ldap' : true"
    @params-changed="updateParams"
  >
    <template v-slot:additional-actions>
      <v-row>
        <v-col
          lg="3"
          md="5"
          sm="5"
        >
          <v-select
            id="iiotApiPermissionsCategoryFilter"
            ref="select"
            v-model="selectedCategory"
            :items="categories"
            :label="$t('access.roles.roleDetails.categoriesTitle')"
            prepend-icon="mdi-filter-outline"
            class="pa-0 mt-4 default-search-input"
            @change="filterBy()"
            attach
          />
        </v-col>
      </v-row>
    </template>
  </the-nerve-table>
</template>

<script>
import TheNerveTable from 'nerve-ui-components/components/TheNerveTable.vue';
import Logger from '@/utils/logger';

export default {
  components: {
    TheNerveTable,
  },
  data() {
    return {
      selectedCategory: '',
      isLoggedLdapUser: false,
      fetchParams: {
        searchText: '',
        categories: '',
      },
    };
  },
  computed: {
    apiPermissionTableHeaders() {
      return [
        {
          text: this.$t('access.roles.roleDetails.apiNameHeader'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('access.roles.roleDetails.apiCategoryHeader'),
          value: 'category.name',
          sortable: false,
        },
      ];
    },
    categories() {
      const categoryNames = ['ALL'];
      this.$store.getters['roles/getCategories'].forEach((category) => {
        if (category.name !== 'UI_PERMISSION') {
          categoryNames.push(category.name);
        }
      });
      return categoryNames;
    },
    role() {
      return this.$store.getters['roles/getRole'];
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch('roles/fetch_categories');
      if (JSON.parse(localStorage.getItem('userDetails')) && JSON.parse(localStorage.getItem('userDetails')).ldap) {
        this.isLoggedLdapUser = true;
      }
    } catch (e) {
      Logger.error(e);
    }
  },
  methods: {
    updateParams(parameters) {
      this.fetchParams = {
        ...parameters,
        categories: this.selectedCategory === 'ALL' ? '' : this.selectedCategory,
      };
    },
    async filterBy() {
      try {
        this.fetchParams.categories = this.selectedCategory === 'ALL' ? '' : this.selectedCategory;
        await this.$store.dispatch('roles/search_permissions', this.fetchParams);
      } catch (e) {
        Logger.error(e);
      }
    },
  },
};
</script>
